<template>
    <b-card no-body>
        <b-tabs pills card class="" v-model="activeTab" @input="changeTab">
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab v-for="tab in tabs" :key="tab.id" :title="tab.title">
                <b-form-group>
                    <label for="title">Select Title</label>
                    <b-form-select
                    v-model="selectedTitle"
                    :options="tabTitles"
                    @change="updateTabTitle"
                    ></b-form-select>
                </b-form-group>
                <div v-if="tab.title != 'Video'" class="mt-2">
                
                <div slot="with-padding">
                    <vue-dropzone
                    ref="myVueDropzone"
                    :destroyDropzone="false"
                    @vdropzone-removed-file="onImageRemove($event, tab.stepId)"
                    @vdropzone-mounted="dropzoneMounted(tab.stepId)"
                    :id="tab.stepId"
                    :options="{
                        ...multidropzoneOptions,
                        url: stepUploadUrl + `/${tab.stepId}`,
                    }"
                    ></vue-dropzone>
                </div>
                
                </div>
                <div v-else>
                <b-form-file
                    v-model="file1"
                    accept="video/mp4"
                    :state="Boolean(file1)"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="onFileChange"
                ></b-form-file>
                <video
                v-show="videoUrl"
                class="video_tag mt-2"
                :src="videoUrl"
                id="videocontainer"
                height="90%"
                controls
                style="max-height: 60vh; width: -webkit-fill-available;"
                ></video>
                </div>
                <div class="mt-2">
                    <b-button
                    size="sm"
                    variant="danger"
                    class="float-right"
                    @click="closeTab(tab)"
                    >
                    Delete Step
                    </b-button>
                </div>
            </b-tab>

            <!-- New Tab Button (Using tabs-end slot) -->
            <template #tabs-end>
                <b-nav-item role="presentation" @click.prevent="newTab" href="#">
                <b>+</b>
                </b-nav-item>
            </template>

            <!-- Render this if no tabs -->
            <template #empty>
                <div class="text-center text-muted">
                There are no open tabs<br />
                Open a new tab using the <b>+</b> button above.
                </div>
            </template>

            <!-- New Tab Button (Using tabs-end slot) -->
            <template #tabs-end>
                <b-nav-item role="presentation" @click.prevent="newTab" href="#"
                ><b>+ Add Step</b></b-nav-item
                >
            </template>

            <!-- Render this if no tabs -->
            <template #empty>
                <div class="text-center text-muted">
                There are no open tabs<br />
                Open a new tab using the <b>+</b> button above.
                </div>
            </template>
        </b-tabs>
    </b-card>
</template>

<script>
import config from "../../services/globalLit";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import InsightService from "../../services/InsightService";

export default {
   props: {
    insight_id: {
        type: String
    },
    isEdit: {
        type: Boolean,
        required: true,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  beforeDestroy() {
    this.is_destroying = true;
  },
  destroyed() {
    this.is_destroying = false;
  },
  data() {
    return {
      file1: null,
      videoUrl: null,
      subInsight_id: null,
      stepUploadUrl: config.uploadStepImage,
      multidropzoneOptions: {
        // url: "/",

        destroyDropzone: false,
        defaultHeaders: true,
        withCredentials: true,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        // uploadMultiple:true,
        createImageThumbnails: true,
        dictRemoveFileConfirmation: 'Are you sure to delete this image?',
        maxFilesize: 2,
        maxFiles: 12,
        addRemoveLinks: true,
        acceptedFiles: "image/jpeg,image/png",
        dictDefaultMessage:
          "<i class='icon-cloud-up'></i><h6>Drop files here or click to upload.</h6>",
      },
      tabs: [],
      tabCounter: 0,
      selectedTitle: null,
      activeTab: null, // To track the active tab
      tabTitles: [
        { value: null, text: "Choose a title" },
        { value: "Home Page", text: "Home Page" },
        {value: 'Consent', text: 'Consent'},
        {value: 'Ad Page', text: 'Ad Page'},
        {value: 'Ad Page1', text: 'Ad Page1'},
        {value: 'Ad Page2', text: 'Ad Page2'},
        { value: "MSISDN Entry", text: "MSISDN Entry" },
        { value: "PIN Message", text: "PIN Message" },
        { value: "PIN Page", text: "PIN Page" },
        {
          value: "Subscription Confirm Message",
          text: "Subscription Confirm Message",
        },
        { value: "Content", text: "Content" },
        { value: "Cancel Subscription", text: "Cancel Subscription" },
        {
          value: "Unsubscription Confirm Message",
          text: "Unsubscription Confirm Message",
        },
        { value: "Video", text: "Video" },
      ]
    };
  },
  mounted() {
    this.subInsight_id = this.insight_id
    if(this.isEdit){
        this.initStepData();
    }
  },
  watch: {
    insight_id(newVal) {
      this.subInsight_id = newVal;
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type === 'video/mp4') {
        this.videoUrl = URL.createObjectURL(file);
        this.file1 = file; // Store the file for uploading
        console.log("file upload", this.file1)
        this.uploadVideo(); // Automatically upload the file
      } else {
        this.videoUrl = '';
      }
    },
    async uploadVideo(){
         if (!this.file1) return;
 
          const formData = new FormData();
          formData.append('id', this.subInsight_id)
          formData.append('videoFile', this.file1);

          await InsightService.uploadVideo(formData)
    }, 

    async onImageRemove(e, stepId) {
      if (this.is_destroying) {
        return;
      }
      let payload = {
        insight_step_id: stepId,
        index: e.name,
      };

      try {
        await InsightService.removeStepImage(payload);
      } catch (e) {
        console.error("Error in deleting image");
        console.error(e);
      }
    },
    async dropzoneMounted(id) {
      let step = this.tabs.find((t) => t.stepId === id);

      let dropzone = this.$refs.myVueDropzone.find(
        (dropzone) => dropzone.id === `${id}`
      );
      

      if (dropzone) {
        for (let i = 0; i < step.imageUrls.length; i++) {
          const url = step.imageUrls[i];
          const URLParams = new URLSearchParams(url.split("?")[1]);
          const index = URLParams.get("index");

          const response = await fetch(step.imageUrls[i]);
          const blob = await response.blob();

          const file = new File([blob], `index_${index}`, {
            type: "image/png",
          });

          let fileObj = {
            size: file.size,
            name: file.name,
            type: file.type,
            lastModified: file.lastModified,
          };

          dropzone.manuallyAddFile(fileObj, url);
          // dropzone.addFile(file);
        }
      }
    },
    async initStepData() {
      this.tabs = [];
      this.tabCounter = 0;
      this.activeTab = null;
      this.selectedTitle = null;
      if (this.subInsight_id) {
        let stepsResponse = await InsightService.getInsightsSteps(
          this.subInsight_id
        );
    
        if (stepsResponse.result) {
          let steps = stepsResponse.data;
          steps.forEach((step) => {
            let tab = {
              id: this.tabCounter,
              title: step.title,
              imageUrls: step.imagesUrl,
              stepId: step._id,
            };
            if(step.title == 'Video'){
                this.videoUrl = step.videoUrl
            }
            this.tabs.push(tab);
            this.tabCounter++;
          });
        }
      }
    },
    changeTab() {
      
      const tab = this.tabs.find((t) => t.id === this.activeTab);
      if (tab) {
        this.selectedTitle = tab.title;
      }
    },
    onImageChange(event, tabId) {
      const files = event.target.files;
      if (files.length) {
        const tab = this.tabs.find((t) => t.id === tabId);
        if (tab) {
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onload = (e) => {
              tab.imageUrls.push(e.target.result); // Store each image URL
            };
            reader.readAsDataURL(files[i]);
          }
        }
      }
    },
    async updateStep(tab) {
      try {
        let payload = {
          insight_step_id: tab.stepId,
          updateObj: {
            title: tab.title,
          },
        };
        let response = await InsightService.updateStep(payload);
        return response;
      } catch (e) {
        console.error("Error in updating tab title");
      }
    },
    async updateTabTitle() {
    
      if (this.activeTab !== null) {
        const tab = this.tabs.find((t) => t.id === this.activeTab);
        if (tab) {
          tab.title = this.selectedTitle ? this.selectedTitle : `Tab ${tab.id}`;
          let response = await this.updateStep(tab);
        }
      }
    },
    async deleteStep(stepId) {
      try {
        let payload = {
          insight_step_id: stepId,
        };

        if (confirm("Are you sure you want to delete")) {
          let response = await InsightService.deleteStep(payload);
          this.init();
          let variant = response.result ? "success" : "error";
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: variant,
            duration: 2000,
          });
          this.tabCounter--;
          return response;
        }
      } catch (e) {
        console.error("Error in deleting step");
      }
    },
    async closeTab(closeTab) {
      this.tabs = this.tabs.filter((tab) => tab.id !== closeTab.id);
      let tabId = closeTab.id;

      await this.deleteStep(closeTab.stepId);

      // Reset tabCounter if there are no tabs left
      if (this.tabs.length === 0) {
        this.tabCounter = 0;
      }

      // Reset active tab if closed
      if (this.activeTab === tabId) {
        this.activeTab = this.tabs.length ? this.tabs[0].id : null;
      }
    },
    async saveStep(step) {
      try {
        let response = await InsightService.saveStep(step);

        if (response.result) {
          return response.data;
        }
      } catch (e) {
        console.error("Error in saving step");
      }
      return response;
    },
    async newTab() {
      let newTab = {
        id: this.tabCounter,
        insight_id: this.subInsight_id,
        title: `Step ${this.tabCounter}`,
        imageUrls: [], // Changed to store multiple image URLs
      };

      try {
        let response = await this.saveStep(newTab);
        newTab.stepId = response._id;
      } catch (e) {
        console.error("Error in saving step");
        console.error(e);
      }

      this.tabs.push(newTab);
      this.tabCounter++;
      setTimeout(() => {
        this.activeTab = newTab.id; // Set the new tab as active
      }, 50) 
      // console.log("activeTab", this.activeTab);
      this.selectedTitle = newTab.title; // Set dropdown to new tab's title
      // console.log("selectedTitle", this.selectedTitle);
    },
  },
};
</script>
